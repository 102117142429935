import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/b4AwINDQnC4"
    bibleGroupSrc="https://pdfhost.io/v/lSJ~GFoMf_Microsoft_Word_Bible_Group_Homework_1220docx.pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/48064657"
  >
    <SEO title="Choices - Holiday on its Head" />
  </Layout>
)

export default SermonPost
